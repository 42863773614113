var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Grid, } from "@mui/material";
import { rightPanelstyles as styles } from "./helper/styles";
import { ExportTextCheckBox } from "./helper/CheckBoxComp";
import { styled } from "@mui/material/styles";
var StyledTypography = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        paddingRight: "10px",
        color: "#1976D2",
        cursor: "pointer",
        fontWeight: "bold",
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        transition: "all 0.3s ease",
        position: "relative",
        "&:hover": {
            color: "#1565C0",
        },
        "&::before": {
            content: '""',
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "0",
            height: "0",
            backgroundColor: "rgba(25, 118, 210, 0.1)",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
            transition: "width 0.3s ease, height 0.3s ease",
        },
        "&:hover::before": {
            width: "150%",
            height: "150%",
        },
    });
});
var RightPanel = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var acessControlList = _a.acessControlList, isLoading = _a.isLoading, translate = _a.translate, exportFields = _a.exportFields, setExportFields = _a.setExportFields, exportDateInfoFields = _a.exportDateInfoFields, setExportDateInfoFields = _a.setExportDateInfoFields, isMobileScreen = _a.isMobileScreen;
    var handleSelectAll = function () {
        // make all key true in "exportFields"
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        console.log("Original exportFields:", exportFields); // Log original state
        console.log("Cloned exportFields:", updatedExportFields); // Log cloned state
        function setAllValuesToTrue(obj) {
            console.log("Processing object:", obj); // Log current object being processed
            for (var key in obj) {
                console.log("Processing key: ".concat(key)); // Log each key being processed
                if (key === "examDate" || key === "analyzeDate") {
                    console.log("Skipping key: ".concat(key)); // Log skipped keys
                    continue; // Ignoring examDate and analyzeDate
                }
                else if (typeof obj[key] === "object") {
                    console.log("Recursing into nested object at key: ".concat(key)); // Log recursion
                    setAllValuesToTrue(obj[key]); // Recursively handle nested objects
                }
                else {
                    console.log("Setting ".concat(key, " to true")); // Log value changes
                    obj[key] = true;
                }
            }
        }
        setAllValuesToTrue(updatedExportFields);
        console.log("Updated exportFields:", updatedExportFields); // Log final state
        setExportFields(updatedExportFields);
    };
    var handleReset = function (criteria) {
        // if is criteria, only set date to false
        // setExportFields({});
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        var exceptions = ["examDate", "analyzeDate"];
        if (criteria) {
            updatedExportFields["examDate"] = false;
            updatedExportFields["analyzeDate"] = false;
        }
        else {
            // Define keys to exclude from being set to true
            function setAllValuesToFalseExcept(obj, exceptions) {
                for (var key in obj) {
                    if (typeof obj[key] === "object") {
                        setAllValuesToFalseExcept(obj[key], exceptions); // Recursively handle nested objects
                    }
                    else if (!exceptions.includes(key)) {
                        obj[key] = false;
                    }
                }
            }
            // Call the function to update all values to true except for 'examDate' and 'analyzeDate'
            setAllValuesToFalseExcept(updatedExportFields, exceptions);
        }
        setExportFields(updatedExportFields);
    };
    var handleChildCheckBox = function (checked, name, checkBoxType) {
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        updatedExportFields[name] = checked;
        setExportFields(updatedExportFields);
    };
    var handleParentCheckBox = function (checked, name, checkBoxType) {
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        // loop through whole child
        var childKeys = Object.keys(updatedExportFields[name]);
        childKeys.map(function (el, idx) {
            updatedExportFields[name][el] = checked;
        });
        setExportFields(updatedExportFields);
    };
    var handleCheckBox = function (checked, name, checkBoxType) {
        var updatedExportFields = JSON.parse(JSON.stringify(exportFields));
        var splitNames = name.split("_");
        updatedExportFields[splitNames[0]][splitNames[1]] = checked;
        setExportFields(updatedExportFields);
    };
    var handleChangeDate = function (value, checkBoxName, dateName) {
        var updatedExportDateInfoFields = __assign({}, exportDateInfoFields);
        updatedExportDateInfoFields[dateName] = value;
        setExportDateInfoFields(updatedExportDateInfoFields);
    };
    return (_jsx(Grid, { container: true, display: "flex", flexWrap: "wrap", children: _jsxs(Grid, { item: true, style: styles.dataExportContainer, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", children: [_jsx(Grid, { className: "normal-text", children: translate("pages.dataExport.name") }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", children: [_jsx(StyledTypography, { onClick: handleSelectAll, children: translate("pages.common.selection.select_all") }), _jsx(StyledTypography, { paddingX: 3, onClick: function () {
                                        handleReset();
                                    }, children: translate("pages.common.selection.reset") })] })] }), _jsx(ExportTextCheckBox, { name: "subjectInformation", label: translate("pages.dataExport.fields.subject_information"), 
                    // checkBoxType="child"
                    checked: exportFields.subjectInformation, handleChange: handleChildCheckBox }), _jsx(ExportTextCheckBox, { name: "images", label: translate("pages.dataExport.fields.images"), checked: exportFields.images.fp || exportFields.images.oct, handleChange: handleParentCheckBox }), _jsx(ExportTextCheckBox, { name: "images_fp", label: translate("pages.dataExport.fields.fps"), checkBoxType: "child", checked: exportFields.images.fp, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "images_oct", label: translate("pages.dataExport.fields.oct_reports"), checkBoxType: "child", checked: exportFields.images.oct, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADReports", label: translate("pages.dataExport.fields.retinAD_reports"), checked: exportFields.retinADReports.fp ||
                        exportFields.retinADReports.oct ||
                        exportFields.retinADReports.fpAndoct, handleChange: handleParentCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADReports_fp", label: translate("pages.dataExport.fields.fp"), checkBoxType: "child", checked: exportFields.retinADReports.fp, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADReports_oct", label: translate("pages.dataExport.fields.oct"), checkBoxType: "child", checked: exportFields.retinADReports.oct, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADReports_fpAndoct", label: translate("pages.dataExport.fields.fp_oct"), checkBoxType: "child", checked: exportFields.retinADReports.fpAndoct, handleChange: handleCheckBox }), (((_b = acessControlList.data_export_normal) === null || _b === void 0 ? void 0 : _b.export_retinad_probability_scores) || ((_c = acessControlList.data_export_admin) === null || _c === void 0 ? void 0 : _c.export_retinad_probability_scores)) && (_jsxs(_Fragment, { children: [_jsx(ExportTextCheckBox, { name: "retinADProbabilityScores", label: translate("pages.dataExport.fields.retinAD_probability_scores"), labelRed: translate("pages.dataExport.fields.internal_use"), checked: ((_d = exportFields.retinADProbabilityScores) === null || _d === void 0 ? void 0 : _d.fp) ||
                                ((_e = exportFields.retinADProbabilityScores) === null || _e === void 0 ? void 0 : _e.oct) ||
                                ((_f = exportFields.retinADProbabilityScores) === null || _f === void 0 ? void 0 : _f.fpAndoct), handleChange: handleParentCheckBox, height: isMobileScreen ? "4rem" : null }), _jsx(ExportTextCheckBox, { name: "retinADProbabilityScores_fp", label: translate("pages.dataExport.fields.fp"), checkBoxType: "child", checked: (_g = exportFields.retinADProbabilityScores) === null || _g === void 0 ? void 0 : _g.fp, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADProbabilityScores_oct", label: translate("pages.dataExport.fields.oct"), checkBoxType: "child", checked: (_h = exportFields.retinADProbabilityScores) === null || _h === void 0 ? void 0 : _h.oct, handleChange: handleCheckBox }), _jsx(ExportTextCheckBox, { name: "retinADProbabilityScores_fpAndoct", label: translate("pages.dataExport.fields.fp_oct"), checkBoxType: "child", checked: (_j = exportFields.retinADProbabilityScores) === null || _j === void 0 ? void 0 : _j.fpAndoct, handleChange: handleCheckBox })] })), _jsx(ExportTextCheckBox, { name: "questionnaireResults", label: translate("pages.dataExport.fields.questionnaire_results"), checked: exportFields.questionnaireResults, handleChange: handleChildCheckBox })] }) }));
};
export default RightPanel;
