var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Card, Grid, Typography, Switch, Box, Container, Dialog, DialogTitle, DialogContent, DialogActions, Button, } from '@mui/material';
import { BarChart, Bar, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import GroupIcon from '@mui/icons-material/Group';
import ApiIcon from '@mui/icons-material/Api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDataProvider, useTranslate, useNotify, useRefresh, } from 'react-admin';
var Cooperation = function () {
    var dataProvider = useDataProvider();
    var translate = useTranslate();
    var notify = useNotify();
    var refresh = useRefresh();
    var _a = useState([]), companies = _a[0], setCompanies = _a[1];
    var _b = useState(false), dialogOpen = _b[0], setDialogOpen = _b[1];
    var _c = useState(null), selectedCompany = _c[0], setSelectedCompany = _c[1];
    useEffect(function () {
        dataProvider.getInvoiceData()
            .then(function (_a) {
            var data = _a.data;
            var formattedData = data.map(function (company, index) { return ({
                id: index + 1,
                name: company.name,
                isActive: true,
                isEnabled: company.isActive,
                monthlyAccess: company.monthlyAccess || Array(12).fill(0),
                totalAccess: company.totalAccess || 0
            }); });
            setCompanies(formattedData);
        })
            .catch(function (error) {
            console.error('Error fetching cooperation data:', error);
            setCompanies([
                {
                    id: 1234234,
                    name: 'Topcon Healthcare',
                    isActive: true,
                    isEnabled: true,
                    monthlyAccess: [150, 230, 180, 290, 210, 170, 220, 260, 240, 190, 280, 310],
                    totalAccess: 2730,
                },
                {
                    id: 2234234,
                    name: 'Eyris',
                    isActive: true,
                    isEnabled: true,
                    monthlyAccess: [120, 190, 160, 220, 180, 150, 200, 230, 210, 170, 250, 280],
                    totalAccess: 2360,
                }
            ]);
        });
    }, []);
    var toggleCompanyVisibility = function (companyId) {
        setCompanies(companies.map(function (company) {
            return company.id === companyId
                ? __assign(__assign({}, company), { isActive: !company.isActive }) : company;
        }));
    };
    var handleAPIToggle = function (company) {
        if (company.isEnabled) {
            setSelectedCompany(company);
            setDialogOpen(true);
        }
        else {
            enableCompanyAPI(company.name);
        }
    };
    var enableCompanyAPI = function (companyName) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, dataProvider.enableApiAccess(companyName)];
                case 1:
                    _a.sent();
                    setCompanies(companies.map(function (company) {
                        return company.name === companyName
                            ? __assign(__assign({}, company), { isEnabled: true }) : company;
                    }));
                    notify(translate('pages.cooperation.notify.enableSuccess'), { type: 'success' });
                    refresh();
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error('Error enabling API access:', error_1);
                    notify(translate('pages.cooperation.notify.enableError'), { type: 'error' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var disableCompanyAPI = function (companyName) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, dataProvider.disableApiAccess(companyName)];
                case 1:
                    _a.sent();
                    setCompanies(companies.map(function (company) {
                        return company.name === companyName
                            ? __assign(__assign({}, company), { isEnabled: false }) : company;
                    }));
                    notify(translate('pages.cooperation.notify.disableSuccess'), { type: 'success' });
                    refresh();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error('Error disabling API access:', error_2);
                    notify(translate('pages.cooperation.notify.disableError'), { type: 'error' });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleConfirmDisable = function () {
        if (selectedCompany) {
            disableCompanyAPI(selectedCompany.name);
        }
        setDialogOpen(false);
        setSelectedCompany(null);
    };
    var handleCancelDisable = function () {
        setDialogOpen(false);
        setSelectedCompany(null);
    };
    var activeCompanies = companies.filter(function (company) { return company.isActive; });
    var statsCards = [
        {
            label: translate('pages.cooperation.stats.totalCompanies'),
            value: companies.length,
            icon: _jsx(GroupIcon, { style: { fontSize: '50px', color: 'white' } }),
            highlight: false,
        },
        {
            label: translate('pages.cooperation.stats.totalApiCalls'),
            value: companies.reduce(function (sum, company) { return sum + company.totalAccess; }, 0),
            icon: _jsx(ApiIcon, { style: { fontSize: '50px', color: 'white' } }),
            highlight: false,
        },
        {
            label: translate('pages.cooperation.stats.activeCompanies'),
            value: companies.filter(function (company) { return company.isEnabled; }).length,
            icon: _jsx(CheckCircleIcon, { style: { fontSize: '50px', color: 'white' } }),
            highlight: false,
        },
    ];
    // Prepare data for monthly access bar chart
    var barChartData = Array.from({ length: 12 }, function (_, i) { return (__assign({ month: (i + 1).toString() }, activeCompanies.reduce(function (acc, company) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[company.name] = company.monthlyAccess[i], _a)));
    }, {}))); });
    // Colors for pie chart
    var COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    return (_jsx(Box, { children: _jsx(Container, { maxWidth: "xl", children: _jsxs(Box, { p: 0, children: [_jsx(Grid, { container: true, spacing: 2, marginBottom: 3, children: statsCards.map(function (card, index) { return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 3, children: _jsx(Card, { elevation: 5, style: {
                                    padding: '15px',
                                    background: card.highlight
                                        ? 'linear-gradient(45deg, #E69500 30%, #E6C200 90%)'
                                        : 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: '20px',
                                    color: 'white',
                                    boxShadow: card.highlight
                                        ? '0 6px 12px rgba(235, 195, 0, 0.3)'
                                        : '0 6px 12px rgba(13, 130, 223, 0.3)',
                                    transition: 'all 0.3s ease-in-out',
                                    transform: 'translateY(0)',
                                }, children: _jsxs(Grid, { container: true, style: {
                                        height: '100%',
                                        display: 'flex',
                                        flexWrap: "wrap",
                                        alignItems: 'center',
                                        justifyContent: "space-between",
                                    }, children: [_jsx(Grid, { item: true, style: {
                                                width: '5em',
                                                height: '5em',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: 'rgba(255, 255, 255, 0.2)',
                                                borderRadius: '50%',
                                                padding: '15px'
                                            }, children: card.icon }), _jsxs(Grid, { item: true, style: { width: "50%", textAlign: 'right', flexGrow: 1 }, children: [_jsx(Typography, { variant: "h6", style: {
                                                        fontWeight: "normal",
                                                        color: 'white',
                                                        textShadow: '1px 1px 2px rgba(0,0,0,0.1)'
                                                    }, lineHeight: "1em", children: card.label }), _jsx(Typography, { variant: "h4", style: {
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        marginTop: '10px',
                                                        textShadow: '2px 2px 4px rgba(0,0,0,0.2)'
                                                    }, children: card.value })] })] }) }) }, index)); }) }), _jsx(Grid, { container: true, spacing: 2, marginBottom: 3, children: companies.map(function (company) { return (_jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(Card, { elevation: 3, sx: {
                                    borderRadius: '20px',
                                    transition: 'all 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'translateY(-5px)',
                                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                    }
                                }, children: _jsx(Box, { p: 3, children: _jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", children: [_jsxs(Box, { children: [_jsx(Typography, { variant: "h6", color: "primary", children: company.name }), _jsxs(Typography, { variant: "body2", color: "text.secondary", children: [translate('pages.cooperation.company.totalApiCalls'), ": ", company.totalAccess] }), _jsxs(Typography, { variant: "body2", color: company.isEnabled ? "success.main" : "error.main", children: [translate('pages.cooperation.company.apiStatus'), ": ", company.isEnabled ? translate('pages.cooperation.company.enabled') : translate('pages.cooperation.company.disabled')] })] }), _jsxs(Box, { display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1, children: [_jsxs(Box, { display: "flex", alignItems: "center", gap: 1, children: [_jsx(Typography, { variant: "body2", color: "text.secondary", children: translate('pages.cooperation.company.showStatistics') }), _jsx(Switch, { checked: company.isActive, onChange: function () { return toggleCompanyVisibility(company.id); }, color: "primary" })] }), _jsxs(Box, { display: "flex", alignItems: "center", gap: 1, children: [_jsx(Typography, { variant: "body2", color: "text.secondary", children: translate('pages.cooperation.company.apiAccess') }), _jsx(Switch, { checked: company.isEnabled, onChange: function () { return handleAPIToggle(company); }, color: "success" })] })] })] }) }) }) }, company.id)); }) }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(Card, { elevation: 3, sx: {
                                        borderRadius: '20px',
                                        overflow: 'hidden'
                                    }, children: [_jsx(Box, { sx: {
                                                background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                                                width: '100%',
                                                p: 1.5,
                                            }, children: _jsx(Typography, { color: "white", fontWeight: "bold", children: translate('pages.cooperation.charts.monthlyApiAccess') }) }), _jsx(Box, { p: 3, children: _jsx(Box, { height: 400, children: _jsx(ResponsiveContainer, { width: "100%", height: "100%", children: _jsxs(BarChart, { data: barChartData, children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "month" }), _jsx(YAxis, {}), _jsx(Tooltip, {}), _jsx(Legend, {}), activeCompanies.map(function (company, index) { return (_jsx(Bar, { dataKey: company.name, fill: COLORS[index % COLORS.length] }, company.name)); })] }) }) }) })] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(Card, { elevation: 3, sx: {
                                        borderRadius: '20px',
                                        overflow: 'hidden'
                                    }, children: [_jsx(Box, { sx: {
                                                background: 'linear-gradient(135deg, #D32F2F 0%, #C62828 100%)',
                                                width: '100%',
                                                p: 1.5
                                            }, children: _jsx(Typography, { color: "white", fontWeight: "bold", children: translate('pages.cooperation.charts.apiAccessDistribution') }) }), _jsx(Box, { p: 3, children: _jsx(Box, { height: 400, children: _jsx(ResponsiveContainer, { width: "100%", height: "100%", children: _jsxs(PieChart, { children: [_jsx(Pie, { data: activeCompanies, dataKey: "totalAccess", nameKey: "name", cx: "50%", cy: "50%", outerRadius: 150, fill: "#8884d8", children: activeCompanies.map(function (entry, index) { return (_jsx(Cell, { fill: COLORS[index % COLORS.length] }, "cell-".concat(index))); }) }), _jsx(Tooltip, {}), _jsx(Legend, {})] }) }) }) })] }) })] }), _jsxs(Dialog, { open: dialogOpen, onClose: handleCancelDisable, sx: {
                            '& .MuiDialog-paper': {
                                borderRadius: '10px'
                            }
                        }, children: [_jsx(DialogTitle, { children: translate('pages.cooperation.dialog.confirmDisableTitle') }), _jsx(DialogContent, { children: _jsx(Typography, { children: translate('pages.cooperation.dialog.confirmDisableMessage') }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleCancelDisable, color: "primary", children: translate('pages.cooperation.dialog.cancel') }), _jsx(Button, { onClick: handleConfirmDisable, color: "error", variant: "contained", children: translate('pages.cooperation.dialog.disableAccess') })] })] })] }) }) }));
};
export default Cooperation;
