var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Datagrid, FunctionField, List, useDataProvider, useResourceContext, useTranslate, useNotify, useRefresh } from 'react-admin';
import { dbDateHelper, } from '../utils/helper';
import { Button, Chip, IconButton, useMediaQuery, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BlockIcon from '@mui/icons-material/Block';
import globalStyles from "../styles/globalStyles";
import { generateInvoice } from '../utils/invoiceGenerator';
import CloseIcon from '@mui/icons-material/Close';
var ApiBaseUrl = import.meta.env.VITE_API_BASE_URL.replace('/api', '');
var PaymentRecord = function (props) {
    var translate = useTranslate();
    var dataProvider = useDataProvider();
    var resource = useResourceContext(props);
    var notify = useNotify();
    var refresh = useRefresh();
    var _a = useState(false), openDialog = _a[0], setOpenDialog = _a[1];
    var _b = useState(null), selectedRecord = _b[0], setSelectedRecord = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var handleViewInvoice = function (record) {
        if (!record.invoicePdf) {
            notify('Invoice not found, you can try to download a invoice first', { type: 'warning' });
            return;
        }
        window.open("".concat(ApiBaseUrl).concat(record.invoicePdf), '_blank');
    };
    var handleOpenDialog = function (record) {
        setSelectedRecord(record);
        setOpenDialog(true);
    };
    var handleCloseDialog = function () {
        setOpenDialog(false);
        setSelectedRecord(null);
    };
    var handleDownloadInvoice = function (lang) { return __awaiter(void 0, void 0, void 0, function () {
        var invoiceData, pdfBlob, url, link, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, 5, 6]);
                    setLoading(true);
                    return [4 /*yield*/, dataProvider.getInvoiceData(selectedRecord.id)];
                case 1:
                    invoiceData = _a.sent();
                    return [4 /*yield*/, generateInvoice(invoiceData.data, lang)];
                case 2:
                    pdfBlob = _a.sent();
                    // Upload PDF
                    return [4 /*yield*/, dataProvider.uploadInvoicePdf('payment', {
                            data: {
                                id: selectedRecord.id,
                                file: new File([pdfBlob], "invoice-".concat(selectedRecord.id, ".pdf"), {
                                    type: 'application/pdf'
                                })
                            }
                        })];
                case 3:
                    // Upload PDF
                    _a.sent();
                    url = window.URL.createObjectURL(pdfBlob);
                    link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "invoice-".concat(selectedRecord.id, ".pdf"));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                    notify(translate('pages.postPayment.invoiceUploaded'), { type: 'success' });
                    handleCloseDialog();
                    // Refresh and reload
                    refresh();
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error handling invoice:', error_1);
                    notify(translate('pages.postPayment.invoiceError'), { type: 'warning' });
                    handleCloseDialog();
                    return [3 /*break*/, 6];
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var rowHeaderStyle = isTabScreen ? {
        display: "none"
    } : {};
    var postRowStyle = function (record, index) { return ({
        // backgroundColor: isTabScreen && index%2==0 ? 'WhiteSmoke' : 'white',
        border: '1px solid Gainsboro'
    }); };
    return (_jsxs(Grid, { padding: 2, children: [_jsxs(Card, { style: {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
                }, children: [_jsx(CardHeader, { title: _jsx(Box, { sx: { display: 'flex', justifyContent: 'center', width: '100%' }, children: _jsx("span", { children: translate('paymentRecord.transactionRecord') }) }), style: {
                            background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                            color: 'white',
                            padding: '10px',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { style: {
                            width: '100%',
                            padding: 0,
                        }, children: _jsx(List, { resource: "payment/records", sx: {
                                '& .RaList-main': {
                                    padding: '16px 24px',
                                    backgroundColor: '#fff',
                                },
                                '& .MuiTableHead-root': {
                                    backgroundColor: '#f5f5f5',
                                    '& .MuiTableCell-head': {
                                        fontWeight: 600,
                                        color: '#424242',
                                        fontSize: '0.875rem',
                                        padding: '12px 16px',
                                        textAlign: 'center',
                                    }
                                },
                                '& .MuiTableBody-root': {
                                    '& .MuiTableRow-root': {
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5',
                                            transition: 'background-color 0.2s ease',
                                        },
                                        '& .MuiTableCell-body': {
                                            padding: '16px',
                                            borderBottom: '1px solid #e0e0e0',
                                            fontSize: '0.875rem',
                                            textAlign: 'center',
                                        }
                                    },
                                    '& .MuiTableRow-root:last-child .MuiTableCell-body': {
                                        borderBottom: 'none'
                                    }
                                }
                            }, children: _jsxs(Datagrid, { style: { width: '100%', tableLayout: "fixed" }, sx: {
                                    '& .RaDatagrid-row': rowStyle,
                                    '& .RaDatagrid-headerCell': rowHeaderStyle,
                                    '& .RaDatagrid-rowCell': rowCellStyle,
                                }, bulkActionButtons: false, rowStyle: postRowStyle, children: [_jsx(FunctionField, { label: translate('paymentRecord.transactionId'), render: function (record) {
                                            var _a, _b;
                                            return (_jsxs("div", { style: __assign(__assign({}, globalStyles.centerContainer), { justifyContent: 'center' }), children: [isTabScreen && (_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.educationLevel"), ":"] })), _jsx(Box, { sx: {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            width: '12em',
                                                            margin: '0 auto',
                                                        }, children: _jsxs(Box, { sx: {
                                                                position: 'relative',
                                                                width: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                border: '1px solid #e0e0e0',
                                                                borderRadius: '4px',
                                                                padding: '4px 8px',
                                                                paddingRight: '32px',
                                                            }, children: [_jsx(Typography, { sx: {
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        width: '100%',
                                                                        textAlign: 'center',
                                                                    }, children: ((_a = record === null || record === void 0 ? void 0 : record.id) === null || _a === void 0 ? void 0 : _a.length) > 15 ? "".concat(record === null || record === void 0 ? void 0 : record.id.substring(0, 15), "...") : (_b = record === null || record === void 0 ? void 0 : record.id) !== null && _b !== void 0 ? _b : "" }), _jsx(IconButton, { size: "small", onClick: function () {
                                                                        var _a;
                                                                        navigator.clipboard.writeText((_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : "");
                                                                    }, sx: {
                                                                        position: 'absolute',
                                                                        right: 2,
                                                                        padding: '4px',
                                                                        '&:hover': {
                                                                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                                                        }
                                                                    }, children: _jsx(ContentCopyIcon, { fontSize: "small" }) })] }) })] }));
                                        } }), _jsx(FunctionField, { label: translate('paymentRecord.transactionDate'), render: function (record) {
                                            var _a, _b;
                                            return (_jsx(Typography, { children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("paymentRecord.transactionDate"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper((_a = record === null || record === void 0 ? void 0 : record.created_at) !== null && _a !== void 0 ? _a : "") })] })
                                                    : dbDateHelper((_b = record === null || record === void 0 ? void 0 : record.created_at) !== null && _b !== void 0 ? _b : "") }));
                                        } }), _jsx(FunctionField, { source: "product", label: translate('paymentRecord.product'), render: function (record) {
                                            var _a, _b;
                                            return (_jsx(Typography, { children: isTabScreen ?
                                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate('paymentRecord.product'), ":"] }), _jsx(Typography, { className: "small-text", children: (_a = record === null || record === void 0 ? void 0 : record.product) !== null && _a !== void 0 ? _a : "" })] })
                                                    : (_b = record === null || record === void 0 ? void 0 : record.product) !== null && _b !== void 0 ? _b : "" }));
                                        } }), _jsx(FunctionField, { label: translate('paymentRecord.status'), render: function (record) { return (_jsxs("div", { style: __assign(__assign({}, globalStyles.centerContainer), { justifyContent: 'center' }), children: [isTabScreen &&
                                                    _jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("paymentRecord.status"), ":"] }), _jsx(Button, { variant: "contained", size: "small", color: (record === null || record === void 0 ? void 0 : record.status) === 'Completed' ? 'success' : 'error', sx: {
                                                        minWidth: '100px',
                                                        textTransform: 'capitalize',
                                                        borderRadius: '10px'
                                                    }, children: (record === null || record === void 0 ? void 0 : record.status) ? translate("params.dashboard.paymentRecord.".concat(record.status)) : "" })] })); } }), _jsx(FunctionField, { label: translate('paymentRecord.buyerInformation'), render: function (record) {
                                            var _a, _b, _c, _d, _e, _f, _g, _h;
                                            return (_jsxs("div", { style: __assign(__assign({}, globalStyles.centerContainer), { justifyContent: 'center' }), children: [isTabScreen &&
                                                        _jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("paymentRecord.buyerInformation"), ":"] }), _jsxs(Box, { sx: {
                                                            display: 'flex',
                                                            flexWrap: "wrap",
                                                            alignItems: 'center',
                                                            justifyContent: "center",
                                                            gap: 1
                                                        }, children: [_jsx(Typography, { children: (_b = (_a = record === null || record === void 0 ? void 0 : record.buyer) === null || _a === void 0 ? void 0 : _a.username) !== null && _b !== void 0 ? _b : "" }), _jsx(Chip, { label: ((_c = record === null || record === void 0 ? void 0 : record.organization) === null || _c === void 0 ? void 0 : _c.orgName) ?
                                                                    (((_e = (_d = record === null || record === void 0 ? void 0 : record.organization) === null || _d === void 0 ? void 0 : _d.orgName) === null || _e === void 0 ? void 0 : _e.length) > 35 ?
                                                                        "".concat((_f = record === null || record === void 0 ? void 0 : record.organization) === null || _f === void 0 ? void 0 : _f.orgName.substring(0, 35), "...")
                                                                        : (_h = (_g = record === null || record === void 0 ? void 0 : record.organization) === null || _g === void 0 ? void 0 : _g.orgName) !== null && _h !== void 0 ? _h : "") : "", size: "medium", sx: {
                                                                    backgroundColor: '#e3f2fd',
                                                                    color: '#1976d2',
                                                                    fontWeight: 500,
                                                                    borderRadius: '16px',
                                                                    marginTop: '0.2em',
                                                                } })] })] }));
                                        } }), _jsx(FunctionField, { label: translate('paymentRecord.invoice'), render: function (record) { return (_jsxs("div", { style: __assign(__assign({}, globalStyles.centerContainer), { justifyContent: 'center' }), children: [isTabScreen &&
                                                    _jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("paymentRecord.invoice"), ":"] }), record.status === 'Pending' ? (_jsx(IconButton, { disabled: true, children: _jsx(BlockIcon, { sx: { color: "red" } }) })) : record.invoicePdf ? (_jsx(IconButton, { onClick: function () { return handleViewInvoice(record); }, color: "primary", children: _jsx(VisibilityIcon, {}) })) : null, record.status !== 'Pending' && (_jsx(IconButton, { onClick: function () { return handleOpenDialog(record); }, color: "primary", children: _jsx(FileDownloadIcon, {}) }))] })); } })] }) }) })] }), _jsxs(Dialog, { open: openDialog, onClose: handleCloseDialog, sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '16px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        padding: '20px'
                    }
                }, children: [_jsxs(DialogTitle, { sx: { display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', color: 'black', fontSize: '1.4rem' }, children: [translate('paymentRecord.dialog.title'), _jsx(IconButton, { onClick: handleCloseDialog, children: _jsx(CloseIcon, {}) })] }), _jsx(DialogContent, { children: _jsxs(Box, { sx: { display: 'flex', gap: 2, justifyContent: 'center', mt: 2 }, children: [_jsx(Button, { variant: "contained", onClick: function () { return handleDownloadInvoice('en'); }, disabled: loading, children: loading ? translate('paymentRecord.dialog.loading') : 'English' }), _jsx(Button, { variant: "contained", onClick: function () { return handleDownloadInvoice('zh-hk'); }, disabled: loading, children: loading ? translate('paymentRecord.dialog.loading') : '繁體中文' }), _jsx(Button, { variant: "contained", onClick: function () { return handleDownloadInvoice('zh-cn'); }, disabled: loading, children: loading ? translate('paymentRecord.dialog.loading') : '简体中文' })] }) })] })] }));
};
export default PaymentRecord;
