import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { DatagridConfigurable, List, useListContext, FunctionField, } from 'react-admin';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import globalStyles from "../../styles/globalStyles";
import { dbDateHelper } from "../../utils/helper";
import { useMediaQuery, Typography, Grid, Button, Checkbox, } from "@mui/material";
import { useDispatch } from "react-redux";
import "../../styles/global.module.css";
var TableDatagrid = function (_a) {
    var _b, _c;
    var selectedAll = _a.selectedAll, setSelectedAll = _a.setSelectedAll, translate = _a.translate, data = _a.data, setData = _a.setData, isLoading = _a.isLoading, educationLevelArray = _a.educationLevelArray, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, handleBulkSelection = _a.handleBulkSelection, previewExport = _a.previewExport, acessControlList = _a.acessControlList, dataGridHeader = _a.dataGridHeader;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var rowHeaderStyle = isTabScreen ? {
        display: "none"
    } : {};
    var postRowStyle = function (record, index) { return ({
        // backgroundColor: isTabScreen && index%2==0 ? 'WhiteSmoke' : 'white',
        border: '1px solid Gainsboro'
    }); };
    var dispatch = useDispatch();
    var selectedIds = useListContext().selectedIds;
    return (_jsx(Fragment, { children: _jsx(List, { resource: resource, actions: _jsx(_Fragment, {}), sort: { field: 'id', order: 'DESC' }, pagination: false, children: _jsxs(DatagridConfigurable, { style: { tableLayout: "fixed" }, sx: {
                    '& .RaDatagrid-row': rowStyle,
                    '& .RaDatagrid-headerCell': rowHeaderStyle,
                    '& .RaDatagrid-rowCell': rowCellStyle,
                }, bulkActionButtons: false, rowStyle: postRowStyle, isLoading: isLoading, data: data, children: [(((_b = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_normal) === null || _b === void 0 ? void 0 : _b.export) || ((_c = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_admin) === null || _c === void 0 ? void 0 : _c.export)) &&
                        _jsx(FunctionField, { source: "id", sortable: false, label: "", render: function (record) {
                                var _a, _b, _c, _d;
                                var appBarOrg = (_a = localStorage.getItem("appBarOrg")) !== null && _a !== void 0 ? _a : "";
                                return (_jsx(_Fragment, { children: _jsx(Grid, { item: true, md: 3, xs: 4, display: "flex", flexWrap: "wrap", justifyContent: "center", children: ((((_b = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_normal) === null || _b === void 0 ? void 0 : _b.export) === true && appBarOrg === ((_c = record === null || record === void 0 ? void 0 : record.organization) === null || _c === void 0 ? void 0 : _c.orgName)) || ((_d = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_admin) === null || _d === void 0 ? void 0 : _d.export)) ?
                                            _jsx(Checkbox, { sx: {
                                                    padding: "0",
                                                    backgroundColor: "",
                                                    transform: isTabScreen ? "translatex(-2rem)" : "translatex(2rem)",
                                                }, checked: record.selected, onChange: function (e) {
                                                    handleBulkSelection(false, record, record.selected, e.target.checked);
                                                } }) :
                                            _jsx(DisabledByDefaultIcon, { sx: { color: '#d50000', borderRadius: '30px' } }) }) }));
                            } }), _jsx(FunctionField, { source: "customId", label: translate("pages.subject.fields.customId"), sortable: false, render: function (record) {
                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.customId"), ":"] }), _jsx(Typography, { className: "small-text", children: record.customId })] })
                                    : record.customId }));
                        } }), _jsx(FunctionField, { source: "lastname", label: translate("pages.subject.fields.lastname"), render: function (record) {
                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.lastname"), ":"] }), _jsx(Typography, { className: "small-text", children: record.lastname })] })
                                    : record.lastname }));
                        } }), _jsx(FunctionField, { source: "firstname", label: translate("pages.subject.fields.firstname"), render: function (record) {
                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.firstname"), ":"] }), _jsx(Typography, { className: "small-text", children: record.firstname })] })
                                    : record.firstname }));
                        } }), _jsx(FunctionField, { source: "gender", sortable: false, label: translate("pages.subject.fields.gender"), render: function (record) {
                            var displayText = translate("params.subjects.".concat(record.gender));
                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.gender"), ":"] }), _jsx(Typography, { className: "small-text", children: displayText })] })
                                    : displayText }));
                        } }), _jsx(FunctionField, { source: "dateOfBirth", label: translate("pages.subject.fields.dateOfBirth"), render: function (record) {
                            return (_jsx(Typography, { className: "small-text", children: isTabScreen ?
                                    _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.dateOfBirth"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper(record === null || record === void 0 ? void 0 : record.dateOfBirth) })] })
                                    : dbDateHelper(record === null || record === void 0 ? void 0 : record.dateOfBirth) }));
                        } }), _jsx(FunctionField, { source: "educationLevel", sortable: false, label: translate("pages.subject.fields.educationLevel"), render: function (record) {
                            var bgColor;
                            var displayText = translate("params.subjects.".concat(record.educationLevel));
                            switch (record.educationLevel) {
                                case educationLevelArray[0]:
                                    bgColor = "#aad5fd";
                                    break;
                                case educationLevelArray[1]:
                                    bgColor = "#6bb0eb";
                                    break;
                                case educationLevelArray[2]:
                                    bgColor = "#3d7ebe";
                                    break;
                                case educationLevelArray[3]:
                                    bgColor = "#0d5da8";
                                    break;
                                case educationLevelArray[4]:
                                    bgColor = "#004587";
                                    break;
                                case educationLevelArray[5]:
                                    bgColor = "#3d648a";
                                    break;
                            }
                            return (_jsxs("div", { style: globalStyles.centerContainer, children: [isTabScreen ?
                                        _jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.educationLevel"), ":"] })
                                        : _jsx(_Fragment, {}), _jsx(Button, { disabled: true, className: "small-text-button", sx: {
                                            backgroundColor: bgColor,
                                        }, children: displayText })] }));
                        } })] }) }) }));
};
export default TableDatagrid;
