var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-nocheck
import JSZip from "jszip";
// import imagesExport from './ImagesExport'
import axios from "axios";
import { imgSrcPathHelper } from "../../utils/helper";
var ZipDownload = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var zip, i, response, imageData, imageBlob, _i, retinADReports_fp_1, octRep, response, octPdfData, octPdfBlob, zipData, link;
    var _c, _d;
    var imgData = _b.imgData, csvData = _b.csvData, subjectId = _b.subjectId, fileName = _b.fileName, retinADReports_fp = _b.retinADReports_fp;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                zip = new JSZip();
                i = 0;
                _e.label = 1;
            case 1:
                if (!(i < imgData.length)) return [3 /*break*/, 4];
                return [4 /*yield*/, axios.get(imgData[i]['img'], { responseType: "blob", headers: { 'Access-Control-Allow-Origin': '*' } })];
            case 2:
                response = _e.sent();
                imageData = response.data;
                imageBlob = new Blob([imageData], { type: 'image/jpg' });
                zip.file("".concat(((_c = imgData[i]) === null || _c === void 0 ? void 0 : _c["original_name"]) ? "".concat((_d = imgData[i]) === null || _d === void 0 ? void 0 : _d["original_name"]) : "image_".concat(i)), imageBlob);
                _e.label = 3;
            case 3:
                i++;
                return [3 /*break*/, 1];
            case 4:
                // zip csv - with csv blob
                if (csvData) {
                    zip.file('subjectInfo.csv', csvData);
                }
                _i = 0, retinADReports_fp_1 = retinADReports_fp;
                _e.label = 5;
            case 5:
                if (!(_i < retinADReports_fp_1.length)) return [3 /*break*/, 8];
                octRep = retinADReports_fp_1[_i];
                return [4 /*yield*/, axios.get(imgSrcPathHelper(octRep), { responseType: "blob", headers: { 'Access-Control-Allow-Origin': '*' } })];
            case 6:
                response = _e.sent();
                octPdfData = response.data;
                octPdfBlob = new Blob([octPdfData], { type: 'pdf' });
                zip.file("pdf_fp.pdf", octPdfBlob);
                _e.label = 7;
            case 7:
                _i++;
                return [3 /*break*/, 5];
            case 8: return [4 /*yield*/, zip.generateAsync({
                    type: "blob",
                    streamFiles: true,
                })];
            case 9:
                zipData = _e.sent();
                link = document.createElement("a");
                link.href = window.URL.createObjectURL(zipData);
                link.download = fileName ? fileName + ".zip" : "zip-folder.zip";
                link.click();
                return [2 /*return*/];
        }
    });
}); };
export default ZipDownload;
